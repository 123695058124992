import React, { useState } from 'react';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';

import Iconify from '../Iconify/Iconify';
import { SearchbarWrapper } from './SearchbarWrapper.styled';

const Searchbar = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
        )}
        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarWrapper>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Поиск..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              Искать
            </Button>
          </SearchbarWrapper>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};

export default Searchbar;
