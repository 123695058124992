import { ListItemIcon as MuiListItemIcon, styled, ListItemIconProps } from '@mui/material';

export const ListItemIcon = styled(MuiListItemIcon)<ListItemIconProps>({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});
