import { createContext, ReactNode } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';

export const defaultSettings = {
  themeMode: 'dark',
  themeColorPresets: 'orange'
};

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggleMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeColor: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: []
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

interface SettingsProviderProps {
  children: ReactNode;
}

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeColorPresets: initialState.themeColorPresets
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light'
    });
  };

  const onChangeColor = (event) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeColorPresets: initialState.themeColorPresets
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Color
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main
        })),
        // Reset Setting
        onResetSetting
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
