import React, { FC, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  useTheme,
  alpha
} from '@mui/material';

import FormProvider from '../../../coponents/hook-form/FormProvider';
import TextFieldController from '../../../coponents/hook-form/TextFieldController';
import Iconify from '../../../coponents/Iconify/Iconify';
import SelectController from '../../../coponents/hook-form/SelectController';
import { OSVersions, RemoteInfoTypes, ServiceTypes } from '../../../store/types/enums';
import AddRemoteInfoItem from './AddRemoteInfoItem';
import { useCreateTerminalMutation } from '../../../store/api/organisationsApi';

interface CreateEditTerminalDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  organisationId: string;
  terminalId?: string;
}

const TERMINAL_TABS = [
  {
    value: 'general',
    name: 'Основные',
    icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />
  },
  {
    value: 'remote_info',
    name: 'Удаленный доступ',
    icon: <Iconify icon={'codicon:remote-explorer'} width={20} height={20} />
  }
];

const CreateEditTerminalDialog: FC<CreateEditTerminalDialogProps> = ({
  open,
  organisationId,
  terminalId,
  onClose,
  onConfirm
}) => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState('general');
  const isNew = useMemo(() => terminalId === 'new', [terminalId]);

  const [createTerminal] = useCreateTerminalMutation();

  const NewTerminalSchema = Yup.object().shape({
    name: Yup.string().required('Поле не может быть пустым'),
    address: Yup.string(),
    description: Yup.string(),
    restaurant_code: Yup.string(),
    rk_version: Yup.string(),
    cash_os: Yup.string(),
    server_os: Yup.string(),
    service_type: Yup.string(),
    remote_info: Yup.array(
      Yup.object().shape({
        type: Yup.string().required('Поле не может быть пустым'),
        name: Yup.string().required('Поле не может быть пустым'),
        value: Yup.string().required('Поле не может быть пустым'),
        password: Yup.string()
      })
    )
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      address: '',
      description: '',
      restaurant_code: '',
      rk_version: '',
      cash_os: '',
      server_os: '',
      service_type: 'RESTAURANT',
      fiscal_register_count: '1',
      remote_info: []
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewTerminalSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const {
    fields: remoteInfoFields,
    remove,
    swap,
    insert
  } = useFieldArray({
    control: methods.control,
    name: 'remote_info'
  });

  const addRemoteInfo = () => {
    insert(remoteInfoFields.length, { type: RemoteInfoTypes[0].code, name: '', value: '', password: '' });
  };

  const onSubmit = async (formData: any) => {
    console.log(formData);
    createTerminal({ organisationId, ...formData });
    setCurrentTab('general');
    reset();
    onConfirm();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      scroll="body"
      aria-labelledby="terminal-dialog"
      sx={{
        '& .MuiDialog-paper': { width: '1000px', maxWidth: '1000px' },
        '& .MuiBackdrop-root': { background: alpha(theme.palette.grey[900], 0.8) }
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="terminal-dialog">
          {isNew ? 'Новая точка обслуживания' : `Редактирование: ${terminalId}`}
        </DialogTitle>
        <DialogContent sx={{ minHeight: '500px', maxHeight: '500px' }}>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
            sx={{ mb: 2 }}
          >
            {TERMINAL_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
          <Grid>
            {currentTab === 'general' && (
              <Stack spacing={2}>
                <TextFieldController name="name" label="Наименование" disabled={false} />
                <TextFieldController name="description" label="Описание" disabled={false} />
                <TextFieldController name="address" label="Адрес" disabled={false} />
                <Grid container>
                  <Grid item lg={3} sx={{ pr: 2 }}>
                    <SelectController name="service_type" label="Тип">
                      {ServiceTypes.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.label}
                        </option>
                      ))}
                    </SelectController>
                  </Grid>
                  <Grid item lg={4} sx={{ pr: 2 }}>
                    <TextFieldController name="restaurant_code" label="Код ресторана" disabled={false} />
                  </Grid>
                  <Grid item lg={5}>
                    <TextFieldController name="rk_version" label="Версия кассового ПО" disabled={false} />
                  </Grid>
                </Grid>
                <Stack direction="row" gap={2}>
                  <SelectController name="cash_os" label="ОС на кассах">
                    <option key="null" value="null">
                      Не указано
                    </option>
                    {OSVersions.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.label}
                      </option>
                    ))}
                  </SelectController>
                  <SelectController name="server_os" label="ОС на сервере">
                    <option key="null" value="null">
                      Не указано
                    </option>
                    {OSVersions.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.label}
                      </option>
                    ))}
                  </SelectController>
                </Stack>
                <Grid container>
                  <Grid item lg={4} sx={{ pr: 2 }}>
                    <TextFieldController
                      type="number"
                      name="fiscal_register_count"
                      label="Количество ФР"
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
            {currentTab === 'remote_info' && (
              <Stack spacing={2}>
                {remoteInfoFields.map((item, index) => (
                  <AddRemoteInfoItem key={index} name={`remote_info.${index}`} />
                ))}
                <Button
                  startIcon={<Iconify icon="clarity:add-line" />}
                  size="small"
                  variant="text"
                  color="primary"
                  onClick={addRemoteInfo}
                >
                  Добавить удаленный доступ
                </Button>
                <Box flexGrow={1} />
              </Stack>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentTab('general');
              reset();
              onClose();
            }}
            color="inherit"
            sx={{ backgroundColor: theme.palette.grey['500'] }}
            disabled={isSubmitting}
          >
            Отмена
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            Сохранить
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default CreateEditTerminalDialog;
