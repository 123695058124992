import React, { FC, PropsWithChildren } from 'react';
import { FormProvider as Form } from 'react-hook-form';

interface FormProviderProps {
  methods: any;
  onSubmit?: any;
}

const FormProvider: FC<PropsWithChildren<FormProviderProps>> = ({ children, onSubmit, methods }) => {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
};

export default FormProvider;
