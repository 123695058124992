import HttpClient from './HttpClient';

export * from './hooks/useAxios';

export const api = {
  getRKPayerAnalytic: (dateStart: string, dateEnd: string) =>
    HttpClient.get(`/api/custom-analytics/rk-pay?date_start=${dateStart}&date_end=${dateEnd}`),
  getRKPayerAnalyticCSV: (dateStart: string, dateEnd: string) =>
    HttpClient.get(`/api/custom-analytics/rk-pay-csv?date_start=${dateStart}&date_end=${dateEnd}`, {
      responseType: 'blob'
    })
};
