import React, { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';

interface SwitchControllerProps {
  name: string;
  label: string;
  [key: string]: any;
}

const SwitchController: FC<SwitchControllerProps> = ({ name, disabled = false, ...other }) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Switch {...field} checked={field.value} disabled={disabled} />}
        />
      }
      disabled={disabled}
      {...other}
    />
  );
};

export default SwitchController;
