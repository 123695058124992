import { styled, Toolbar as MuiToolbar, ToolbarProps } from '@mui/material';

import { HEADER } from '../config/constants';

export const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  minHeight: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5)
  }
}));
