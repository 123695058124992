import React from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import './App.css';
import { createRouter, routes } from './config/routes';
import ThemeProvider from './config/theme';
import ThemeColorPresets from './coponents/ThemeColorPresets';

function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Routes>{routes.map((route, index) => createRouter(route, index))}</Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

export default App;
