import React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../../../coponents/hook-form/FormProvider';
import { Box, Card, Grid, Stack } from '@mui/material';
import SwitchController from '../../../coponents/hook-form/SwitchController';
import TextFieldController from '../../../coponents/hook-form/TextFieldController';
import { LoadingButton } from '@mui/lab';
import AuthService from '../../../auth/AuthService';
import { useGetCurrentUserQuery } from '../../../store/api/usersApi';

const AccountGeneral = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = AuthService.getUser();
  const { data, error, isLoading } = useGetCurrentUserQuery();

  const UpdateUserSchema = Yup.object().shape({
    userName: Yup.string(),
    firstName: Yup.string().required('Поле не может быть пустым'),
    lastName: Yup.string().required('Поле не может быть пустым')
  });

  const defaultValues = {
    userName: user?.userName || '',
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    isBlocked: user?.isBlocked || false
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Изменения сохранены', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <SwitchController name="isBlocked" labelPlacement="start" label="Заблокирован" sx={{ mt: 5 }} />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
              }}
            >
              <TextFieldController name="firstName" label="Имя" />
              <TextFieldController name="lastName" label="Фамилия" />
              <TextFieldController name="email" label="E-mail" disabled={true} />
              <TextFieldController name="userName" label="Username" />
              <TextFieldController name="phoneNumber" label="Телефон" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Сохранить изменения
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default AccountGeneral;
