import React from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import FormProvider from '../../../coponents/hook-form/FormProvider';
import TextFieldController from '../../../coponents/hook-form/TextFieldController';

const AccountChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Поле не может быть пустым'),
    newPassword: Yup.string().min(6, 'Пароль не может быть менее 6 символов').required('Поле не может быть пустым'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать')
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar('Пароль успешно изменен!', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <TextFieldController name="oldPassword" type="password" label="Старый пароль" />
          <TextFieldController name="newPassword" type="password" label="Новый пароль" />
          <TextFieldController name="confirmNewPassword" type="password" label="Подтверждение нового пароля" />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Сохранить
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
};

export default AccountChangePassword;
