import React, { FC } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';

interface ClientListHeadProps {
  headLabel: any[];
  rowCount: number;
  numSelected: number;
  onSelectAllClick: () => void;
}

const ClientListHead: FC<ClientListHeadProps> = ({ headLabel, numSelected, rowCount, onSelectAllClick }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ padding: '0 0 0 10px' }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
            {...(headCell.width ? { width: headCell.width } : {})}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ClientListHead;
