import React from 'react';
import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material';

import Page from '../../coponents/Page/Page';
import AppWidgetSummaryLight from '../../coponents/AppWidgetSummary/AppWidgetSummaryLight';
import { formatNumber } from '../../utils/formatNumber';

const Dashboard = () => {
  const theme = useTheme();

  return (
    <Page title="Рабочий стол">
      <Container maxWidth="xl">
        <Stack gap={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummaryLight
                title="Количество клиентов"
                showPercent
                chartData={[23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]}
                total={formatNumber(0, 0)}
                chartColor={theme.palette.primary.main}
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Page>
  );
};

export default Dashboard;
