import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from '../../coponents/Page/Page';
import FormProvider from '../../coponents/hook-form/FormProvider';
import TextFieldController from '../../coponents/hook-form/TextFieldController';
import SwitchController from '../../coponents/hook-form/SwitchController';
import {
  useCreateOrganisationMutation,
  useEditOrganisationMutation,
  useLazyGetOrganisationByIdQuery
} from '../../store/api/organisationsApi';

const CreateEditClient = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();

  const isNew = useMemo(() => id === 'new', [id]);

  const [trigger, { data: clientData, isLoading }] = useLazyGetOrganisationByIdQuery();
  const [createOrganisation] = useCreateOrganisationMutation();
  const [editOrganisation] = useEditOrganisationMutation();

  useEffect(() => {
    if (!isNew) {
      trigger(id);
    }
  }, []);

  useEffect(() => {
    if (clientData !== undefined) {
      const { id, is_horeca_rest, is_pb, ...props } = clientData;
      reset({ ...props, use_rest: is_horeca_rest, use_pb: is_pb });
    }
  }, [clientData]);

  const NewClientSchema = Yup.object().shape({
    name: Yup.string().required('Поле не может быть пустым'),
    inn: Yup.string(),
    kpp: Yup.string(),
    address: Yup.string(),
    is_blocked: Yup.boolean(),
    use_rest: Yup.boolean(),
    use_pb: Yup.boolean()
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      inn: '',
      kpp: '',
      address: '',
      is_blocked: false,
      use_rest: false,
      use_pb: false
    }),
    [clientData]
  );

  const methods = useForm({
    resolver: yupResolver(NewClientSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (formData: any) => {
    const { name, inn, kpp, address, is_blocked, use_rest, use_pb } = formData;

    // TODO: Заменить на вызов функции сохранения/редактирования
    const f = isNew ? createOrganisation : editOrganisation;

    try {
      f({
        id,
        name,
        inn,
        kpp,
        address,
        is_blocked,
        is_horeca_rest: use_rest,
        is_pb: use_pb
      })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(({ error }) => {
          if (error) {
            enqueueSnackbar(`Ошибка при ${isNew ? 'создании' : 'редактировании'} клиента`, { variant: 'error' });
          } else {
            reset();
            enqueueSnackbar(`Клиент успешно ${isNew ? 'создан' : 'сохранен'}`, { variant: 'success' });
            navigate(-1);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Новый клиент">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="h4">{isNew ? 'Новый клиент' : 'Редактирование клиента'}</Typography>
        </Stack>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextFieldController name="name" label="Наименование" disabled={isLoading} />
                  <Stack direction="row" spacing={3}>
                    <TextFieldController name="inn" label="ИНН" disabled={isLoading} />
                    <TextFieldController name="kpp" label="КПП" disabled={isLoading} />
                  </Stack>
                  <TextFieldController name="address" label="Адрес" disabled={isLoading} />
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Stack gap={3}>
                    <SwitchController name="is_blocked" label="Заблокирован" disabled={isNew || isLoading} />
                    <SwitchController name="use_rest" label="HoReCa.REST" disabled={isLoading} />
                    <SwitchController name="use_pb" label="PremiumBonus" disabled={isLoading} />
                  </Stack>
                </Card>
                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                  {isNew ? 'Создать клиента' : 'Сохранить изменения'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </Page>
  );
};

export default CreateEditClient;
