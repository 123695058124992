import axios, { AxiosInstance } from 'axios';
import AuthService from '../auth/AuthService';

export const getBaseURI = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case 'development':
      url = 'http://localhost:3000';
      break;
    default:
      url = 'https://api.catlabs.tech';
  }

  return url;
};

const HttpClient: AxiosInstance = axios.create({
  baseURL: getBaseURI()
});

HttpClient.interceptors.request.use(
  (config) => {
    const cb = () => {
      config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
      return Promise.resolve(config);
    };
    return AuthService.updateToken(cb);
  },
  (error) => {
    console.log(error);
  }
);

export default HttpClient;
