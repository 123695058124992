import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Card, Container, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { useLazyGetTerminalByIdQuery } from '../../store/api/organisationsApi';
import Page from '../../coponents/Page/Page';
import Iconify from '../../coponents/Iconify/Iconify';
import LabeledValue from '../../coponents/LabeledValue/LabeledValue';
import { osVersions, remoteInfoType, serviceType } from '../../store/types/enums';

const TerminalPage = () => {
  const theme = useTheme();
  const { id, terminalId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [trigger, { data: terminalData, isLoading }] = useLazyGetTerminalByIdQuery();

  useEffect(() => {
    trigger({ organisationId: id, id: terminalId });
  }, [id, terminalId]);

  if (isLoading || terminalData === undefined) {
    return <div>Loading ...</div>;
  }

  return (
    <Page title={`${terminalData.name} (${state['organisationName']})`}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mb: 4 }} gap={2}>
          <IconButton color="primary" onClick={() => navigate(-1)}>
            <Iconify icon="eva:arrow-back-fill" width={20} height={20} />
          </IconButton>
          <Typography variant="h4">{`${terminalData.name} (${state['organisationName']})`}</Typography>
          <IconButton onClick={() => navigate('edit')}>
            <Iconify icon="clarity:edit-line" width={20} height={20} />
          </IconButton>
          <Box flexGrow={1} />
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <LabeledValue label="Тип сервиса" value={serviceType[terminalData.service_type]} />
                <Stack direction="row">
                  <LabeledValue label="Описание" value={terminalData.description} />
                  <LabeledValue label="Адрес" value={terminalData.address} />
                </Stack>
                <Stack direction="row">
                  <LabeledValue label="Код ресторана" value={terminalData.restaurant_code} />
                  <LabeledValue label="Версия кассового ПО" value={terminalData.rk_version} />
                  <LabeledValue label="ОС касса" value={osVersions[terminalData.cash_os]} />
                  <LabeledValue label="ОС сервер" value={osVersions[terminalData.server_os]} />
                </Stack>
                <Typography variant="h6" color={theme.palette.grey[500]} sx={{ mt: `48px !important` }}>
                  Удаленные доступы
                </Typography>
                {terminalData.remote_info.map((info) => {
                  return (
                    <Stack direction="row">
                      <LabeledValue label="Тип" value={remoteInfoType[info.type]} />
                      <LabeledValue label="Наименование" value={info.name} />
                      <LabeledValue label="Логин" value={info.value} />
                      <LabeledValue label="Пароль" value={info.password} />
                    </Stack>
                  );
                })}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default TerminalPage;
