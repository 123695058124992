import React, { FC } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

interface RkPayerListHeadProps {
  headLabel?: any[];
}

const RkPayerListHead: FC<RkPayerListHeadProps> = ({ headLabel }) => {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default RkPayerListHead;
