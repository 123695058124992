import React, { FC } from 'react';
import { Card, Chip, Grid, Stack, TextField } from '@mui/material';

import { OrganisationType } from '../../../store/types/organisation';
import LabeledValue from '../../../coponents/LabeledValue/LabeledValue';

interface GeneralProps {
  client: OrganisationType;
}

const General: FC<GeneralProps> = ({ client }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
              <LabeledValue label="ИНН" value={client.inn} />
              <LabeledValue label="КПП" value={client.kpp} />
              {/*<TextField fullWidth label="ИНН" value={client.inn} inputProps={{ readOnly: true }} />*/}
              {/*<TextField fullWidth label="КПП" value={client.kpp} inputProps={{ readOnly: true }} />*/}
            </Stack>
            <LabeledValue label="Адрес" value={client.address} />
            {/*<TextField fullWidth label="Адрес" value={client.address} inputProps={{ readOnly: true }} />*/}
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Stack gap={2}>
              <Stack gap={3} direction="row">
                {client.is_horeca_rest && <Chip color="primary" label="HoReCa.REST" />}
                {client.is_pb && <Chip color="info" label="PremiumBonus" />}
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default General;
