import React, { FC, PropsWithChildren } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';

interface ConfirmDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  danger?: boolean;
}

const ConfirmDialog: FC<PropsWithChildren<ConfirmDialogProps>> = (props) => {
  const theme = useTheme();
  const { title, children, open, onClose, danger = false, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="inherit"
          sx={{ backgroundColor: theme.palette.grey['500'] }}
        >
          Нет
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color={danger ? 'error' : 'primary'}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
