import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import FileDownload from 'js-file-download';

import Page from '../../coponents/Page/Page';
import { api } from '../../api';
import { RKPayerAnalyticType } from './types';
import { formatNumber } from '../../utils/formatNumber';
import AppWidgetSummaryLight from '../../coponents/AppWidgetSummary/AppWidgetSummaryLight';
import RkPayerListHead from './RKPayerListHead';
import Iconify from '../../coponents/Iconify/Iconify';
import { useDownloadFile } from '../../hooks/useDownloadFile';

const TABLE_HEAD = [
  { id: 'id', label: '#', alignCenter: true, alignRight: false },
  { id: 'pos_id', label: 'POS ID', alignRight: false },
  { id: 'cheque_count', label: 'Количество чеков', alignRight: false },
  { id: 'sum', label: 'Сумма чеков', alignRight: false },
  { id: 'cheque_avg_sum', label: 'Средний чек', alignRight: false }
];

const RkPayerAnalyticPage = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [onlinePos, setOnlinePos] = useState<{ total: number; online: number }>({ total: 0, online: 0 });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<RKPayerAnalyticType | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    const dateStart = startDate.startOf('day').format('yyyy-MM-DDTHH:mm:ss.SSS');
    const dateEnd = endDate.endOf('day').format('yyyy-MM-DDTHH:mm:ss.SSS');

    api.getRKPayerAnalytic(dateStart, dateEnd).then(({ data }) => {
      const onlineCount = data.pos_analytic.reduce((acc, item) => (item.online ? acc + 1 : acc), 0);

      setOnlinePos({ total: data.pos_analytic.length, online: onlineCount });
      setData(data);
      setIsLoading(false);
    });
  }, [startDate, endDate]);

  const handleResetDate = () => {
    setStartDate(moment().startOf('month'));
    setEndDate(moment().endOf('month'));
  };

  const getFileName = () => {
    return moment().format('DD_MM_yyyy') + '_rk_analytic.csv';
  };

  const handleGetCSVFile = () => {
    setIsLoading(true);
    const dateStart = startDate.startOf('day').format('yyyy-MM-DDTHH:mm:ss.SSS');
    const dateEnd = endDate.endOf('day').format('yyyy-MM-DDTHH:mm:ss.SSS');

    api.getRKPayerAnalyticCSV(dateStart, dateEnd).then(({ data }) => {
      FileDownload(data, getFileName());
      setIsLoading(false);
    });
  };

  return (
    <Page title="Аналитика RK-Payer">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="h4">Аналитика RK-Payer</Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <DesktopDatePicker
              label="Дата начала"
              inputFormat="DD.MM.YYYY"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => <TextField {...params} size="small" disabled={isLoading} />}
            />
            <DesktopDatePicker
              label="Дата конца"
              inputFormat="DD.MM.YYYY"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => <TextField {...params} size="small" disabled={isLoading} />}
            />
            <Button variant="outlined" onClick={handleResetDate} disabled={isLoading}>
              Сбросить
            </Button>
            {/*<a href={url} download={name} className="hidden" ref={ref} />*/}
            <Button
              variant="outlined"
              color="success"
              startIcon={<Iconify icon="ph:file-csv-light" width={24} height={24} />}
              onClick={handleGetCSVFile}
              disabled={isLoading}
            >
              Выгрузить CSV
            </Button>
          </Stack>
        </Stack>
        {isLoading ? (
          <div>Загрузка...</div>
        ) : (
          <Stack gap={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummaryLight
                  title="Количество чеков"
                  total={formatNumber(data.total_cheque, 0)}
                  chartColor={theme.palette.primary.main}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummaryLight
                  title="Сумма чеков"
                  total={formatNumber(data.total_sum, 2)}
                  chartColor={theme.palette.primary.main}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummaryLight
                  title="Средний чек"
                  total={formatNumber(data.total_sum / data.total_cheque, 2)}
                  chartColor={theme.palette.primary.main}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummaryLight
                  title="POS On-line"
                  total={`${onlinePos.online} / ${onlinePos.total}`}
                  chartColor={theme.palette.primary.main}
                />
              </Grid>
            </Grid>

            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <RkPayerListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {data.pos_analytic.map((item, index) => {
                      return (
                        <TableRow hover key={index} tabIndex={-1} role="checkbox">
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {item.online ? (
                                <Iconify
                                  icon="heroicons-solid:status-online"
                                  color={theme.palette.success.dark}
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <Iconify
                                  icon="heroicons-outline:status-offline"
                                  color={theme.palette.error.dark}
                                  width={24}
                                  height={24}
                                />
                              )}
                              <Typography variant="subtitle2">{item.pos_id}</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{formatNumber(item.cheque_count, 0)}</TableCell>
                          <TableCell align="left">{formatNumber(item.sum)}</TableCell>
                          <TableCell align="left">{formatNumber(item.cheque_avg_sum)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Stack>
        )}
      </Container>
    </Page>
  );
};

export default RkPayerAnalyticPage;
