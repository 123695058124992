import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import SelectController from '../../../coponents/hook-form/SelectController';
import { RemoteInfoTypes } from '../../../store/types/enums';
import TextFieldController from '../../../coponents/hook-form/TextFieldController';

interface AddRemoteInfoItemProps {
  name: string;
}

const AddRemoteInfoItem: FC<AddRemoteInfoItemProps> = ({ name }) => {
  return (
    <Grid container>
      <Grid item lg={3} sx={{ pr: 2 }}>
        <SelectController name={`${name}.type`} label="Тип доступа">
          {RemoteInfoTypes.map((option) => (
            <option key={option.code} value={option.code}>
              {option.label}
            </option>
          ))}
        </SelectController>
      </Grid>
      <Grid item lg={5} sx={{ pr: 2 }}>
        <TextFieldController name={`${name}.name`} label="Наименование" disabled={false} />
      </Grid>
      <Grid item lg={2} sx={{ pr: 2 }}>
        <TextFieldController name={`${name}.value`} label="Логин" disabled={false} />
      </Grid>
      <Grid item lg={2}>
        <TextFieldController name={`${name}.password`} label="Пароль" disabled={false} />
      </Grid>
    </Grid>
  );
};

export default AddRemoteInfoItem;
