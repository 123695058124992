import React, { FC } from 'react';
import { Box, Stack, IconButton } from '@mui/material';

import { NavBarRoot } from './NavBarRoot.styled';
import { Toolbar } from '../Toolbar.styled';
import Iconify from '../Iconify/Iconify';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover/AccountPopover';

interface NavbarProps {
  onOpenSidebar: () => void;
}

export const Navbar: FC<NavbarProps> = ({ onOpenSidebar }) => {
  return (
    <NavBarRoot>
      <Toolbar sx={{ backgroundColor: 'transparent' }}>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </NavBarRoot>
  );
};
