import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeaders } from '../utils';
import { OrganisationType, TerminalType } from '../types/organisation';
import { PageType } from '../types/common';

interface EntityListQueryProps {
  offset?: number;
  limit?: number;
}

interface TerminalQueryProps extends EntityListQueryProps {
  organisationId: string;
}

interface TerminalCreateProps extends Partial<TerminalType> {
  organisationId: string;
}

export const organisationsApi = createApi({
  reducerPath: 'organisations',
  tagTypes: ['Organisation', 'Terminal'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/organisation',
    prepareHeaders: prepareHeaders
  }),
  endpoints: (builder) => ({
    getOrganisations: builder.query<PageType<OrganisationType>, EntityListQueryProps>({
      query: ({ offset = 0, limit = 10 }) => `?offset=${offset}&limit=${limit}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Organisation' as const, id })),
              { type: 'Organisation', id: 'LIST' }
            ]
          : [{ type: 'Organisation', id: 'LIST' }]
    }),
    getOrganisationById: builder.query<OrganisationType, string>({
      query: (id: string) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Organisation', id }]
    }),
    createOrganisation: builder.mutation<OrganisationType, Partial<OrganisationType>>({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Organisation', id: 'LIST' }]
    }),
    editOrganisation: builder.mutation<OrganisationType, Partial<OrganisationType>>({
      query: ({ id, ...body }) => ({
        url: `/update?id=${id}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ({ id }) => {
        return [{ type: 'Organisation', id }];
      }
    }),
    deleteOrganisation: builder.mutation<boolean, string[]>({
      query: (ids) => ({
        url: `/delete?ids=${ids}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => {
        return [{ type: 'Organisation', id: 'LIST' }];
      }
    }),
    getOrganisationTerminals: builder.query<PageType<TerminalType>, TerminalQueryProps>({
      query: ({ organisationId, offset = 0, limit = 10 }) =>
        `${organisationId}/terminals?offset=${offset}&limit=${limit}`,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Terminal' as const, id })), { type: 'Terminal', id: 'LIST' }]
          : [{ type: 'Terminal', id: 'LIST' }]
    }),
    getTerminalById: builder.query<TerminalType, { organisationId: string; id: string }>({
      query: ({ organisationId, id }) => `/${organisationId}/terminals/${id}`,
      providesTags: (result, error, arg) => [{ type: 'Terminal', id: arg.id }]
    }),
    createTerminal: builder.mutation<TerminalType, TerminalCreateProps>({
      query: ({ organisationId, ...body }) => {
        console.log('body', body);

        return {
          url: `/${organisationId}/terminals`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: [{ type: 'Terminal', id: 'LIST' }]
    })
  })
});

export const {
  useGetOrganisationsQuery,
  useGetOrganisationByIdQuery,
  useLazyGetOrganisationByIdQuery,
  useCreateOrganisationMutation,
  useEditOrganisationMutation,
  useDeleteOrganisationMutation,
  useGetOrganisationTerminalsQuery,
  useGetTerminalByIdQuery,
  useLazyGetTerminalByIdQuery,
  useCreateTerminalMutation
} = organisationsApi;
