import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { UserType } from '../types/user';
import { prepareHeaders } from '../utils';

export const userApi = createApi({
  reducerPath: 'users',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/users',
    prepareHeaders: prepareHeaders
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserType, void>({
      query: () => '/current',
      providesTags: (result) => [{ type: 'User' as const, id: result.id }]
      // providesTags: (result) =>
      //   result
      //     ? [...result.map(({ id }) => ({ type: 'User' as const, id })), { type: 'User', id: 'LIST' }]
      //     : [{ type: 'User', id: 'LIST' }]
    })
  })
});

export const { useGetCurrentUserQuery } = userApi;
