export enum LicenseType {
  PremiumBonus = 'premium-bonus',
  HoReCaREST = 'horeca-rest'
}

export const LicenseTypes = [
  {
    code: 'premium-bonus',
    label: 'PremiumBonus'
  },
  {
    code: 'horeca-rest',
    label: 'HoReCa.REST'
  }
];

export enum OSVersion {
  WINDOWS_XP = 'WINDOWS_XP',
  WINDOWS_7 = 'WINDOWS_7',
  WINDOWS_10 = 'WINDOWS_10',
  WINDOWS_11 = 'WINDOWS_11',
  WINDOWS_SERVER_2012 = 'WINDOWS_SERVER_2012',
  WINDOWS_SERVER_2016 = 'WINDOWS_SERVER_2016',
  WINDOWS_SERVER_2019 = 'WINDOWS_SERVER_2019'
}

export const OSVersions = [
  {
    code: 'WINDOWS_XP',
    label: 'Windows XP'
  },
  {
    code: 'WINDOWS_7',
    label: 'Windows 7'
  },
  {
    code: 'WINDOWS_10',
    label: 'Windows 10'
  },
  {
    code: 'WINDOWS_11',
    label: 'Windows 11'
  },
  {
    code: 'WINDOWS_SERVER_2012',
    label: 'Windows Server 2012'
  },
  {
    code: 'WINDOWS_SERVER_2016',
    label: 'Windows Server 2016'
  },
  {
    code: 'WINDOWS_SERVER_2019',
    label: 'Windows Server 2019'
  }
];

export const osVersions = OSVersions.reduce((acc, item) => {
  return {
    ...acc,
    [item.code]: item.label
  };
}, {});

export enum InternetType {
  CABLE = 'CABLE',
  MOBILE = 'MOBILE',
  SATELLITE = 'SATELITE'
}

export const InternetTypes = [
  {
    code: 'CABLE',
    label: 'Кабель'
  },
  {
    code: 'MOBILE',
    label: 'Мобильный'
  },
  {
    code: 'SATELITE',
    label: 'Спутник'
  }
];

export const internetType = InternetTypes.reduce((acc, item) => {
  return {
    ...acc,
    [item.code]: item.label
  };
}, {});

export enum ServiceType {
  SHOP = 'SHOP',
  FAST_FOOD = 'FAST_FOOD',
  RESTAURANT = 'RESTAURANT'
}

export const ServiceTypes = [
  {
    code: 'SHOP',
    label: 'Магазин'
  },
  {
    code: 'FAST_FOOD',
    label: 'Фаст фуд'
  },
  {
    code: 'RESTAURANT',
    label: 'Ресторан'
  }
];

export const serviceType = ServiceTypes.reduce((acc, item) => {
  return {
    ...acc,
    [item.code]: item.label
  };
}, {});

export enum RemoteInfoType {
  CASH_REMOTE = 'CASH_REMOTE',
  CASH_SERVER_REMOTE = 'CASH_SERVER_REMOTE',
  MANAGER_REMOTE = 'MANAGER_REMOTE',
  CASH = 'CASH',
  MANAGER = 'MANAGER',
  OTHER = 'OTHER'
}

export const RemoteInfoTypes = [
  {
    code: 'CASH_REMOTE',
    label: 'AnyDesk Касса'
  },
  {
    code: 'CASH_SERVER_REMOTE',
    label: 'AnyDesk Кассовый сервер'
  },
  {
    code: 'MANAGER_REMOTE',
    label: 'AnyDesk Менеджер'
  },
  {
    code: 'CASH',
    label: 'Касса'
  },
  {
    code: 'MANAGER',
    label: 'Менеджер'
  },
  {
    code: 'OTHER',
    label: 'Другое'
  }
];

export const remoteInfoType = RemoteInfoTypes.reduce((acc, item) => {
  return {
    ...acc,
    [item.code]: item.label
  };
}, {});
