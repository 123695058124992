import { Route } from 'react-router-dom';

import { RouteItemType } from './types';
import {
  UI_ANALYTICS,
  UI_CLIENTS,
  UI_ENTITY_BY_ID,
  UI_ENTITY_BY_ID_EDIT,
  UI_HOME,
  UI_LICENSES,
  UI_TERMINAL_ID,
  UI_TERMINALS,
  UI_TRACKER,
  UI_USER_SETTINGS
} from './constants';
import RootLayout from '../layouts/RootLayout';
import Dashboard from '../pages/Dashboard/Dashboard';
import BasicLayout from '../layouts/BasicLayout';
import RKPayerAnalyticPage from '../pages/AnalyticsPage/RKPayerAnalyticPage';
import LicensePage from '../pages/LicensesPage/LicensePage';
import ClientsPage from '../pages/Clients/ClientsPage';
import TrackerPage from '../pages/Tracker/TrackerPage';
import SettingsPage from '../pages/Users/SettingsPage';
import CreateEditClient from '../pages/Clients/CreateEditClient';
import ClientPreview from '../pages/Clients/ClientPreview/ClientPreview';
import TerminalPage from '../pages/Terminals/TerminalPage';

export const routes: RouteItemType[] = [
  {
    path: UI_HOME,
    component: RootLayout,
    children: [
      {
        index: true,
        component: Dashboard
      },
      {
        path: UI_TRACKER,
        component: TrackerPage
      },
      {
        path: UI_CLIENTS,
        component: BasicLayout,
        children: [
          {
            index: true,
            component: ClientsPage
          },
          {
            path: UI_ENTITY_BY_ID,
            component: ClientPreview
          },
          {
            path: UI_ENTITY_BY_ID_EDIT,
            component: CreateEditClient
          },
          {
            path: `${UI_ENTITY_BY_ID}/${UI_TERMINALS}/${UI_TERMINAL_ID}`,
            component: TerminalPage
          }
        ]
      },
      {
        path: UI_LICENSES,
        component: LicensePage
      },
      {
        path: UI_ANALYTICS,
        component: BasicLayout,
        children: [
          {
            index: true,
            component: RKPayerAnalyticPage
          }
        ]
      },
      {
        path: UI_USER_SETTINGS,
        component: SettingsPage
      }
    ]
  }
];

export const createRouter = (route: RouteItemType, index: number) => {
  const { component: Component } = route;

  if (!route.children) {
    if (route.index) {
      return <Route index key={index} element={<Component {...route.props} />} />;
    }

    return <Route key={index} path={route.path} element={<Component {...route.props} />} />;
  }

  return (
    <Route key={index} path={route.path} element={<Component {...route.props} />}>
      {route.children.map((childRoute, childIdx) => createRouter(childRoute, childIdx))}
    </Route>
  );
};
