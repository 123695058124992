import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Navbar } from '../coponents/Navbar/Navbar';
import Sidebar from '../coponents/Sidebar/Sidebar';
import { Stack, StackProps, styled } from '@mui/material';
import { HEADER } from '../config/constants';

const LayoutRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const Main = styled(Stack)<StackProps>(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const RootLayout = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LayoutRoot>
      <Navbar onOpenSidebar={() => setOpen(true)} />
      <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </LayoutRoot>
  );
};

export default RootLayout;
