import { configureStore } from '@reduxjs/toolkit';

import { userApi } from './api/usersApi';
import { organisationsApi } from './api/organisationsApi';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [organisationsApi.reducerPath]: organisationsApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware, organisationsApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
