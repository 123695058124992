export const UI_NEW_ENTITY = 'new';
export const UI_ENTITY_BY_ID = ':id';
export const UI_ENTITY_BY_ID_EDIT = `${UI_ENTITY_BY_ID}/edit`;

export const UI_HOME = '/';

export const UI_DASHBOARD = 'dashboard';

export const UI_TRACKER = 'tracker';
export const UI_CLIENTS = 'clients';

export const UI_TERMINALS = 'terminals';
export const UI_TERMINAL_ID = ':terminalId';

export const UI_LICENSES = 'licenses';

export const UI_ANALYTICS = '/analytics';
export const UI_RK_PAYER = 'rk-payer';

export const UI_USER_SETTINGS = '/user-settings';

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
};
