import { AppBar, AppBarProps, styled } from '@mui/material';
import { HEADER, NAVBAR } from '../../config/constants';
import cssStyles from '../../utils/cssStyles';

export const NavBarRoot = styled(AppBar)<AppBarProps>(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  backgroundImage: 'none',
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`
  }
}));
