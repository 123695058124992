import React, { FC, PropsWithChildren, forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, BoxProps, Stack, Typography } from '@mui/material';

interface PageProps extends BoxProps {
  title: string;
  meta?: ReactNode;
}

const Page: FC<PropsWithChildren<PageProps>> = forwardRef(({ children, title, meta, ...other }, ref) => {
  return (
    <>
      <Helmet>
        <title>{`${title} | CatLABS.tech`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
