import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DevSupport } from '@react-buddy/ide-toolbox';

import 'moment/locale/ru';

import App from './App';
import AuthService from './auth/AuthService';
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import ComponentPreviews from './dev/previews';
import { useInitial } from './dev';

import './index.css';

moment.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const renderApp = () =>
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <SettingsProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
              <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
                <App />
              </DevSupport>
            </Provider>
          </LocalizationProvider>
        </SettingsProvider>
      </HelmetProvider>
    </React.StrictMode>
  );

AuthService.initKeycloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
