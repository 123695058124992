import Autocomplete from './Autocomplete';
import Backdrop from './Backdrop';
import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import DataGrid from './DataGrid';
import Switch from './Switch';
import Tabs from './Tabs';
import Typography from './Typography';
import Popover from './Popover';
import Pagination from './Pagination';
import ToggleButton from './ToggleButton';
import Menu from './Menu';
import List from './List';

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Autocomplete(theme),
    Backdrop(theme),
    Button(theme),
    Card(theme),
    CssBaseline(theme),
    DataGrid(theme),
    List(theme),
    Menu(theme),
    Popover(theme),
    Pagination(theme),
    Switch(theme),
    Tabs(theme),
    ToggleButton(theme),
    Typography(theme)
  );
}
