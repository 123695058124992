import { Box, BoxProps, styled } from '@mui/material';

import { NAVBAR } from '../../config/constants';

export const SidebarRoot = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: NAVBAR.DASHBOARD_WIDTH
  }
}));
