import React, { FC, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import Iconify from '../Iconify/Iconify';

export interface MoreMenuItem {
  title: string;
  icon: string;
  color?: string;
  onClick: () => void;
}

interface MoreMenuProps {
  items: MoreMenuItem[];
}

export const MoreMenu: FC<MoreMenuProps> = ({ items }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {items.map((item, index) => {
          const { title, icon, color = 'text.secondary', onClick } = item;

          return (
            <MenuItem key={index} onClick={onClick} sx={{ color }}>
              <ListItemIcon>
                <Iconify icon={icon} width={24} height={24} color={color} />
              </ListItemIcon>
              <ListItemText primary={title} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
