import React, { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Box, List } from '@mui/material';

import NavItem from './NavItem';

interface NavSectionProps {
  navConfig: any[];
  [key: string]: any;
}

const NavSection: FC<NavSectionProps> = ({ navConfig, ...other }) => {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, exact: true, strict: true }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};

export default NavSection;
