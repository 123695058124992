import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';

import Page from '../../coponents/Page/Page';
import Iconify from '../../coponents/Iconify/Iconify';
import { useDeleteOrganisationMutation, useGetOrganisationsQuery } from '../../store/api/organisationsApi';
import ClientListHead from './ClientListHead';
import { OrganisationType } from '../../store/types/organisation';
import { MoreMenu, MoreMenuItem } from '../../coponents/MoreMenu/MoreMenu';
import { UI_NEW_ENTITY } from '../../config/constants';
import ConfirmDialog from '../../coponents/ConfirmDialog/ConfirmDialog';

const TABLE_HEAD = [
  { id: 'name', label: 'Наименование', alignRight: false },
  { id: 'inn', label: 'ИНН', alignRight: false },
  { id: 'kpp', label: 'КПП', alignRight: false },
  // { id: 'services', label: 'Сервисы', alignRight: false },
  { id: 'address', label: 'Адрес', alignRight: false },
  { id: '' }
];

const ClientsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [deletedClients, setDeletedClients] = useState<OrganisationType[] | undefined>(undefined);
  const [selected, setSelected] = useState([]);
  const [clients, setClients] = useState<OrganisationType[]>([]);
  const [clientTotal, setClientTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, error, isLoading, refetch } = useGetOrganisationsQuery({ offset: currentPage, limit: rowsPerPage });
  const [deleteOrganisation] = useDeleteOrganisationMutation();

  useEffect(() => {
    if (data !== undefined) {
      setClients(data.data);
      setClientTotal(data.total);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [rowsPerPage, currentPage]);

  const handleSelection = (event, id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleAllSelect = () => {
    if (selected.length === 0) {
      setSelected(clients.map((item) => item.id));
    } else {
      setSelected([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const handleDeleteClient = () => {
    deleteOrganisation(deletedClients.map(({ id }) => id));
  };

  return isLoading ? (
    <div>Loading ...</div>
  ) : (
    <Page title="Клиенты">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="h4">Клиенты</Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            {selected.length !== 0 && (
              <Stack direction="row" alignItems="center" spacing={2} sx={{ mr: 2 }}>
                <Typography variant="subtitle2">Выбрано: {selected.length}</Typography>
                <IconButton onClick={() => console.log('deactivate')}>
                  <Iconify icon="ion:ban" width={20} height={20} />
                </IconButton>
                <IconButton onClick={() => setDeletedClients(clients.filter(({ id }) => selected.includes(id)))}>
                  <Iconify icon="eva:trash-2-outline" color={theme.palette.error.dark} width={20} height={20} />
                </IconButton>
                <Divider orientation="vertical" sx={{ height: '24px', border: `1px solid ${theme.palette.divider}` }} />
              </Stack>
            )}
            <Button
              variant="outlined"
              startIcon={<Iconify icon="clarity:add-line" />}
              onClick={() => navigate(`${UI_NEW_ENTITY}/edit`)}
            >
              Добавить клиента
            </Button>
          </Stack>
        </Stack>
        <Stack gap={4}>
          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ClientListHead
                  headLabel={TABLE_HEAD}
                  rowCount={clients.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleAllSelect}
                />
                <TableBody>
                  {clients.map((client) => {
                    const isItemSelected = selected.includes(client.id);
                    const MORE_MENU_ITEMS: MoreMenuItem[] = [
                      {
                        title: 'Редактировать',
                        icon: 'clarity:edit-line',
                        onClick: () => navigate(`${client.id}/edit`)
                      },
                      {
                        title: 'Удалить',
                        icon: 'eva:trash-2-outline',
                        color: theme.palette.error.light,
                        onClick: () => setDeletedClients([client])
                      }
                    ];

                    return (
                      <TableRow
                        hover
                        key={client.id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`${client.id}`)}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox checked={isItemSelected} onChange={(event) => handleSelection(event, client.id)} />
                        </TableCell>
                        <TableCell align="left">
                          <Stack alignContent="center" direction="row" gap={2}>
                            {client.is_blocked && (
                              <Iconify icon="gg:block" color={theme.palette.error.dark} width={24} height={24} />
                            )}
                            {client.name}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{client.inn}</TableCell>
                        <TableCell align="left">{client.kpp}</TableCell>
                        <TableCell align="left">{client.address}</TableCell>
                        <TableCell
                          align="right"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <MoreMenu items={MORE_MENU_ITEMS} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={clientTotal}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Записей на странице:"
            />
          </Card>
        </Stack>
      </Container>
      <ConfirmDialog
        title="Удалить клиента?"
        open={deletedClients !== undefined}
        onClose={() => setDeletedClients(undefined)}
        onConfirm={handleDeleteClient}
        danger
      >
        {deletedClients?.length === 1
          ? `Вы уверены что хотите удалить клиента ${deletedClients?.[0].name}?`
          : `Вы уверены что хотите удалить выбранных клиентов?`}
      </ConfirmDialog>
    </Page>
  );
};

export default ClientsPage;
