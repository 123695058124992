import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

import { ScrollbarRoot } from './ScrollbarRoot.styled';
import { SimpleBar } from './SimpleBar.styled';

interface ScrollbarProps {
  sx?: object;
  [key: string]: any;
}

const Scrollbar: FC<PropsWithChildren<ScrollbarProps>> = ({ children, sx = {}, ...other }) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <ScrollbarRoot>
      <SimpleBar timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBar>
    </ScrollbarRoot>
  );
};

export default Scrollbar;
