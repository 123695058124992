export const EMDASH = '–';
export const QUARTERSP = '\u2005';

export function formatNumber(
  number: number | null | undefined,
  decimals = 2,
  hideTrailingZeroes = false,
  withoutRound?: boolean
): string {
  if (number === null || number === undefined) return EMDASH;

  const withTrailingZeroes = withoutRound ? Number(number).toString() : Number(number).toFixed(decimals);
  if (withTrailingZeroes === 'NaN' || withTrailingZeroes === 'Infinity') {
    return EMDASH;
  }

  const baseNumber = hideTrailingZeroes ? withTrailingZeroes.replace(/(\..+?)0+$/, '$1') : withTrailingZeroes;

  return baseNumber.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + QUARTERSP).replace('.', ',');
}
