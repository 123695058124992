import React, { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';

import Page from '../../coponents/Page/Page';
import Iconify from '../../coponents/Iconify/Iconify';
import AccountGeneral from './pages/AccountGeneral';
import AccountChangePassword from './pages/AccountChangePassword';
import { useGetCurrentUserQuery } from '../../store/api/usersApi';

const ACCOUNT_TABS = [
  {
    value: 'general',
    name: 'Основные',
    icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
    component: <AccountGeneral />
  },
  // {
  //   value: 'billing',
  //   icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
  //   component: <AccountBilling cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} />,
  // },
  // {
  //   value: 'notifications',
  //   icon: <Iconify icon={'eva:bell-fill'} width={20} height={20} />,
  //   component: <AccountNotifications />,
  // },
  // {
  //   value: 'social_links',
  //   icon: <Iconify icon={'eva:share-fill'} width={20} height={20} />,
  //   component: <AccountSocialLinks myProfile={_userAbout} />,
  // },
  {
    value: 'change_password',
    name: 'Изменить пароль',
    icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
    component: <AccountChangePassword />
  }
];

const SettingsPage = () => {
  const [currentTab, setCurrentTab] = useState('general');

  const { data, error, isLoading } = useGetCurrentUserQuery();

  return (
    <Page title="Настройки пользователя">
      <Container maxWidth="xl">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
};

export default SettingsPage;
