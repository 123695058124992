import React, { FC } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

interface LabeledValueProps {
  label: string;
  value: string;
}

const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Stack flexGrow={1}>
      <Typography sx={{ mb: 1 }} variant="subtitle2" color={theme.palette.grey[600]}>
        {label}
      </Typography>
      <Typography variant="subtitle1" sx={{ minHeight: '24px' }}>
        {value}
      </Typography>
    </Stack>
  );
};

export default LabeledValue;
