import React from 'react';

import Page from '../../coponents/Page/Page';
import { Button, Container, Stack, Typography } from '@mui/material';
import Iconify from '../../coponents/Iconify/Iconify';

const LicensePage = () => {
  return (
    <Page title="Лицензирование">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Typography variant="h4">Лицензии</Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <Button variant="outlined" startIcon={<Iconify icon="clarity:add-line" />}>
              Добавить лицензию
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
};

export default LicensePage;
