import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Checkbox,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';

import { OrganisationType, TerminalType } from '../../../store/types/organisation';
import { useGetOrganisationTerminalsQuery } from '../../../store/api/organisationsApi';
import ClientListHead from '../ClientListHead';
import { UI_TERMINALS } from '../../../config/constants';

interface GeneralProps {
  client: OrganisationType;
}

const TABLE_HEAD = [
  { id: 'name', label: 'Наименование', alignRight: false, width: '35%' },
  { id: 'address', label: 'Адрес', alignRight: false, width: '40%' },
  { id: 'restaurant_code', label: 'Код ресторана', width: '25%', alignRight: false }
];

const Terminals: FC<GeneralProps> = ({ client }) => {
  const [selected, setSelected] = useState([]);
  const [terminals, setTerminals] = useState<TerminalType[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const { data, error, isLoading, refetch } = useGetOrganisationTerminalsQuery({
    organisationId: client.id,
    offset: currentPage,
    limit: rowsPerPage
  });

  useEffect(() => {
    console.log('data', data);

    if (data !== undefined) {
      setTerminals(data.data);
      setTotal(data.total);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [rowsPerPage, currentPage]);

  const handleSelection = (event, id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleAllSelect = () => {
    if (selected.length === 0) {
      setSelected(terminals.map((item) => item.id));
    } else {
      setSelected([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ClientListHead
                headLabel={TABLE_HEAD}
                rowCount={terminals.length}
                numSelected={selected.length}
                onSelectAllClick={handleAllSelect}
              />
              <TableBody>
                {terminals.map((terminal) => {
                  const isItemSelected = selected.includes(terminal.id);

                  return (
                    <TableRow
                      hover
                      key={terminal.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(`${UI_TERMINALS}/${terminal.id}`, { state: { organisationName: client.name } })
                      }
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox checked={isItemSelected} onChange={(event) => handleSelection(event, terminal.id)} />
                      </TableCell>
                      <TableCell align="left">{terminal.name}</TableCell>
                      <TableCell align="left">{terminal.address}</TableCell>
                      <TableCell align="left">{terminal.restaurant_code}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {terminals.length === 0 && (
            <Stack justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
              Нет данных для отображения
            </Stack>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Записей на странице:"
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Terminals;
