import Iconify from '../coponents/Iconify/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const navConfig = [
  {
    title: 'Рабочий стол',
    path: '/',
    icon: getIcon('icon-park-outline:dashboard')
  },
  {
    title: 'Трекер',
    path: '/tracker',
    icon: getIcon('carbon:intent-request-scale-in')
  },
  {
    title: 'Клиенты',
    path: '/clients',
    icon: getIcon('octicon:organization-24')
  },
  {
    title: 'Лицензии',
    path: '/licenses',
    icon: getIcon('carbon:license-global')
  },
  {
    title: 'Аналитика',
    path: '/analytics',
    icon: getIcon('carbon:analytics'),
    children: [
      {
        title: 'RK-Payer',
        path: '/analytics',
        icon: getIcon('eva:pie-chart-2-fill')
      }
    ]
  }
];
