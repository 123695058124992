import React, { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { alpha, Box, Card, Stack, styled, Typography, useTheme } from '@mui/material';

import { formatNumber } from '../../utils/formatNumber';
import Iconify from '../Iconify/Iconify';

interface AppWidgetSummaryLightProps {
  chartColor: string;
  chartData?: number[];
  percent?: number;
  showPercent?: boolean;
  title: string;
  total: string;
}

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

const AppWidgetSummaryLight: FC<AppWidgetSummaryLightProps> = ({
  title,
  total,
  chartData = [],
  chartColor,
  percent,
  showPercent = false
}) => {
  const theme = useTheme();

  const chartOptions = {
    colors: [chartColor],
    chart: { sparkline: { enabled: true } },
    plotOptions: { bar: { columnWidth: '68%', borderRadius: 2 } },
    tooltip: {
      x: { show: false },
      y: {
        formatter: (seriesName) => formatNumber(seriesName),
        title: {
          formatter: () => ''
        }
      },
      marker: { show: false }
    }
  };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        {showPercent && (
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2, mb: 1 }}>
            <IconWrapperStyle
              sx={{
                ...(percent < 0 && {
                  color: 'error.main',
                  bgcolor: alpha(theme.palette.error.main, 0.16)
                })
              }}
            >
              <Iconify width={16} height={16} icon={percent >= 0 ? 'eva:trending-up-fill' : 'eva:trending-down-fill'} />
            </IconWrapperStyle>
            <Typography component="span" variant="subtitle2">
              {percent > 0 && '+'}
              {formatNumber(percent, 2)}
            </Typography>
          </Stack>
        )}

        <Typography variant="h3">{total}</Typography>
      </Box>

      {chartData.length !== 0 && (
        <ReactApexChart type="bar" series={[{ data: chartData }]} options={chartOptions} width={60} height={36} />
      )}
    </Card>
  );
};

export default AppWidgetSummaryLight;
