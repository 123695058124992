import { styled } from '@mui/material';
import { HEADER } from '../../config/constants';
import cssStyles from '../../utils/cssStyles';

export const SearchbarWrapper = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER.MOBILE_HEIGHT,
  padding: theme.spacing(0, 3),
  boxShadow: theme.shadows[1],
  [theme.breakpoints.up('md')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5)
  }
}));
