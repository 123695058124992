import React, { FC, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Avatar, Box, Drawer, Link, Typography, useTheme } from '@mui/material';

import { useResponsive } from '../../hooks';
import Scrollbar from '../Scrollbar/Scrollbar';
import { Account } from './Account.styled';
import NavSection from '../NavSection/NavSection';
import { navConfig } from '../../config/navConfig';
import { SidebarRoot } from './SidebarRoot.styled';
import { NAVBAR } from '../../config/constants';
import AuthService from '../../auth/AuthService';

interface SidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isOpenSidebar, onCloseSidebar }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Typography variant="h5">CatLABS</Typography>
        <Typography variant="h5" color={theme.palette.error.dark}>
          .Intra
        </Typography>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <Account>
            <Avatar src={AuthService.getUserPicture()} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {AuthService.getFullName()}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {AuthService.getUserRole()}
              </Typography>
            </Box>
          </Account>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <SidebarRoot>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: NAVBAR.DASHBOARD_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </SidebarRoot>
  );
};

export default Sidebar;
