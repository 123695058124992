import Keycloak from 'keycloak-js';

const getKeycloakSettings = () => {
  let options;
  switch (process.env.NODE_ENV) {
    case 'development':
      options = {
        url: 'https://auth.catlabs.tech/auth',
        realm: 'catlabs.tech-dev',
        clientId: 'react-auth'
      };
      break;
    default:
      options = {
        url: 'https://auth.catlabs.tech/auth',
        realm: 'catlabs.tech',
        clientId: 'react-auth'
      };
  }

  return options;
};

const _kc = new Keycloak({ ...getKeycloakSettings() });

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) => {
  return _kc.updateToken(5).then(successCallback).catch(doLogin);
};

const updateTokenAsync = async () => {
  await _kc.updateToken(5);
};

const getFullName = () => `${_kc.tokenParsed?.family_name} ${_kc.tokenParsed?.given_name}`;
const getUsername = () => _kc.tokenParsed?.preferred_username;
const getUserEmail = () => _kc.tokenParsed?.email;
const getUserPicture = () => _kc.tokenParsed?.picture;
const getUserRoles = () => _kc.tokenParsed?.realm_access?.roles || [];

const getUser = () => ({
  firstName: _kc.tokenParsed?.given_name,
  lastName: _kc.tokenParsed?.family_name,
  userName: _kc.tokenParsed?.preferred_username,
  email: _kc.tokenParsed?.email,
  avatar: _kc.tokenParsed?.picture,
  phoneNumber: '',
  isBlocked: false
});

const getUserRole = () => {
  const roles: string[] = getUserRoles();
  console.log('roles', roles);

  switch (true) {
    case roles.includes('system_admin'):
      return 'Администратор';
    default:
      return '';
  }
};

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      // onLoad: 'check-sso',
      // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256'
    })
    .then((authenticated: boolean) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    })
    .catch(console.error);
};

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken,
  updateTokenAsync,
  getFullName,
  getUsername,
  getUserEmail,
  getUserPicture,
  getUserRoles,
  getUserRole,
  getUser
};

export default AuthService;
