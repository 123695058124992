import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, IconButton, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';

import Page from '../../../coponents/Page/Page';
import { useLazyGetOrganisationByIdQuery } from '../../../store/api/organisationsApi';
import Iconify from '../../../coponents/Iconify/Iconify';
import General from './General';
import Terminals from './Terminals';
import { UI_NEW_ENTITY } from '../../../config/constants';
import CreateEditTerminalDialog from '../modals/CreateEditTerminalDialog';
import { TerminalType } from '../../../store/types/organisation';

const CLIENT_TABS = [
  {
    value: 'general',
    name: 'Основные',
    icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
    component: General
  },
  {
    value: 'terminals',
    name: 'Точки обслуживания',
    icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
    component: Terminals
  }
];

const ClientPreview = () => {
  const [currentTab, setCurrentTab] = useState('general');
  const [selectedTerminal, setSelectedTerminal] = useState<string | undefined>(undefined);

  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [trigger, { data: clientData, isLoading }] = useLazyGetOrganisationByIdQuery();

  useEffect(() => {
    trigger(id);
  }, [id]);

  if (isLoading || clientData === undefined) {
    return <div>Loading ...</div>;
  }

  return (
    <Page title={clientData.name}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mb: 4 }} gap={2}>
          <IconButton color="primary" onClick={() => navigate(-1)}>
            <Iconify icon="eva:arrow-back-fill" width={20} height={20} />
          </IconButton>
          <Typography variant="h4">{clientData.name}</Typography>
          <IconButton onClick={() => navigate('edit')}>
            <Iconify icon="clarity:edit-line" width={20} height={20} />
          </IconButton>
          {clientData.is_blocked && (
            <Typography variant="subtitle2" color={theme.palette.error.dark}>
              Клиент заблокирован
            </Typography>
          )}
          <Box flexGrow={1} />
          {currentTab === 'terminals' && (
            <Button
              variant="outlined"
              startIcon={<Iconify icon="clarity:add-line" />}
              onClick={() => setSelectedTerminal('new')}
            >
              Добавить терминал
            </Button>
          )}
        </Stack>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
          sx={{ mb: 4 }}
        >
          {CLIENT_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        {CLIENT_TABS.map((tab, index) => {
          const { component: Component } = tab;
          const isMatched = tab.value === currentTab;

          return isMatched && <Component key={index} client={clientData} />;
        })}
      </Container>
      <CreateEditTerminalDialog
        organisationId={id}
        terminalId={selectedTerminal}
        open={selectedTerminal !== undefined}
        onClose={() => setSelectedTerminal(undefined)}
        onConfirm={() => setSelectedTerminal(undefined)}
      />
    </Page>
  );
};

export default ClientPreview;
